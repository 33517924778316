import Glide from '@glidejs/glide';

function initReferencesCarousel() {
    // inicializace slideru "reference produktu"
    const referenceBlockElements = document.querySelectorAll('.reference-slider');
    if (referenceBlockElements) {
        referenceBlockElements.forEach((referenceBlockElement) => {
            const sliderItems = referenceBlockElement.querySelectorAll('.reference-slider__item');
            const slidesPhysicalCount = sliderItems ? sliderItems.length : 0;

            const perViewHandled = (items) => (items <= slidesPhysicalCount ? items : slidesPhysicalCount);

            const toggleArrows = () => {
                const arrowsWrapperElement = referenceBlockElement.querySelector('.reference-slider__buttons-wrapper');
                if (!arrowsWrapperElement) {
                    return;
                }

                const showArrows = referencesSlider.settings.perView < slidesPhysicalCount;
                if (showArrows) {
                    arrowsWrapperElement.classList.remove('d-none');
                } else {
                    arrowsWrapperElement.classList.add('d-none');
                }
            };

            // eslint-disable-next-line no-unused-vars
            const isDragable = () => {};

            const referencesSlider = new Glide(referenceBlockElement, {
                autoplay: false,
                perView: perViewHandled(1),
                type: 'carousel',
                gap: 0,
                rewind: false,
            });

            referencesSlider.on(['resize', 'mount.before'], () => {
                toggleArrows();
            });

            referencesSlider.mount();
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    initReferencesCarousel();

    window.naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        if (event && event.detail && event.detail.snippet && event.detail.snippet.id === 'snippet--dietCalc') {
            initReferencesCarousel();
        }
    });
});
